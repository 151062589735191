<template>
    <div>
      <coupon-comp :type="type"></coupon-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import CouponComp from "@/components/admin/coupon/CouponComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "view-coupon",
    components : {CouponComp},

    setup() {

        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View Coupon", ["Coupons"] , checkPermission('coupons.store') ? 'create-coupon': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
